import { render, staticRenderFns } from "./ReadComplianceExternal.vue?vue&type=template&id=220ad023&scoped=true"
import script from "./ReadComplianceExternal.vue?vue&type=script&lang=js"
export * from "./ReadComplianceExternal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "220ad023",
  null
  
)

export default component.exports
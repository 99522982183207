<template>
  <v-container>
    <v-btn outlined color="primary" :to="'/external/compliance'">
      Voltar para Compliance
    </v-btn>

    <read-article :article="foundCompliance" />
  </v-container>
</template>

<script>
import { externalBySlug } from '../../services/compliance-service'

export default {
  name: 'ReadComplianceExternal',
  data () {
    return {
      foundCompliance: {}
    }
  },
  async created () {
    this.foundCompliance = await externalBySlug(this.$route.params.slug)
  }
}
</script>

<style scoped>

</style>
